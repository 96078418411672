<template>
  <div class="container">
    <van-search class="self-search" v-model="formData.keyword" show-action placeholder="请输入搜索关键词">
      <template #action>
        <div @click="toSearch">搜索</div>
      </template>
    </van-search>

    <van-tabs class="search-tab" v-model="activeName" @change="tabChangeName">
      <van-tab title="全部" name="all"></van-tab>
      <van-tab title="直播" name="live"></van-tab>
      <van-tab title="会议" name="meeting"></van-tab>
      <van-tab title="课程" name="course"></van-tab>
      <van-tab title="智库" name="article"></van-tab>
      <van-tab title="学会" name="organ"></van-tab>
    </van-tabs>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="tabChange"
    >
      <van-empty :description="word" v-if="list.length == 0" />
      <div v-if="list.length > 0">
        <div class="search-list" v-for="(item, index) in list" :key="index">
          <b class="search-type" v-if="item.type">{{item.type_title}}</b>
          <div class="search-item"  @click="toBVueInfo(item.type, item.id)">
            <div>
              <h4>{{item.title}}</h4>
              <p>{{item.remark}}</p>
            </div>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </van-list>


  </div>
</template>
<script>
export default {
  data() {
    return {
      value: '',
      activeName: 'all',
      list: [],
      loading: false,
      finished: false,
      tabName: '',
      formData: {
        limit: 15,
        offset: 0,
        keyword: ''
      },
      toUrl: '',
      level: 0,
      searchStatus: false,
      word: '数据加载中...'
    }
  },
  created() {
    this.activeName = this.$untils.getUrlKey('activeName') ? this.$untils.getUrlKey('activeName') : this.activeName;
    this.formData.keyword = this.$untils.getUrlKey('keyword');
  },
  methods: {
    onLoad() {
      // // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1);
      //   }
      //   // 加载状态结束
      //   this.loading = false;
      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      // }, 1000);
    },
    toSearch() {
      this.formData.offset = 0
      this.finished = false
      this.tabChangeName(this.activeName)
    },
    tabChangeName(name) {
      this.activeName = name
      this.searchStatus = false
      this.formData.offset = 0
      this.finished = false
      if (name == 'all') {
        this.level = 0
        this.live()
      } if (name == 'live') {
        this.level = 1
        this.toUrl = '/liveDetail'
        this.live()
      } else if (name == 'meeting') {
        this.level = 2
        this.toUrl = '/meetDetail'
        this.meeting()
      } else if (name == 'course') {
        this.level = 3
        this.toUrl = '/courseDetail'
        this.course()
      } else if (name == 'article') {
        this.level = 4
        this.toUrl = '/medicineDetail'
        this.article()
      } else if (name == 'organ') {
        this.level = 5
        this.toUrl = '/organDetail'
        this.activity()
      }
    },
    tabChange() {
      const name = this.activeName
      if (name == 'all') {
        this.live()
      }if (name == 'live') {
        this.live()
      } else if (name == 'meeting') {
        this.meeting()
      } else if (name == 'course') {
        this.course()
      } else if (name == 'article') {
        this.article()
      } else if (name == 'organ') {
        this.activity()
      }
      this.loading = false;
    },
    //直播
    live() {
      let that = this
      if (that.searchStatus || (that.activeName == 'all' && that.level != 0)) {
        return false
      }
      console.log('直播数据')
      console.log(that.activeName, 'live')
      if (that.formData.offset == 0) {
        that.list = []
      }
      that.searchStatus = true
      that.$api.LIVETRAILER_GET(that.formData).then(res => {
        if (res.length === 0 && that.activeName != 'all') {
          that.word = '暂无数据'
          return false
        }
        res.forEach((item, index) => {
          that.list.push({
            id: item.id,
            title: item.title,
            type_title: that.activeName == 'all' && index == 0 && that.formData.offset == 0 ? '直播' : '',
            type: 'live',
            remark: '讲师 ' + item.speaker
          })
        })
        if (res.length < that.formData.limit) {
          that.level = 1
          if (that.activeName != 'all') {
            that.finished = true
          } else if (that.activeName == 'all') {
            that.formData.offset = 0
            that.searchStatus = false
            that.meeting('ing')
            console.log('直播数据完了，抓会议数据')
            return false
          }
        }
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        }
        that.searchStatus = false
      })
    },
    //会议
    meeting(ing = '') {
      let that = this
      if (that.searchStatus || (that.activeName == 'all' && that.level != 1)) {
        return false
      }
      console.log('会议数据', that.level)
      if (that.formData.offset == 0 && ing != 'ing') {
        that.list = []
      }
      that.searchStatus = true
      that.$api.MEETING_GET(that.formData).then(res => {
        if (res.length === 0 && that.activeName != 'all') {
          that.word = '暂无数据'
          return false
        }
        res.forEach((item, index) => {
          that.list.push({
            id: item.id,
            title: item.title,
            type_title: that.activeName == 'all' && index == 0 ? '会议' : '',
            type: 'meeting',
            remark: item.title_sub
          })
        })
        if (res.length < that.formData.limit) {
          that.level = 2
          if (that.activeName != 'all') {
            that.finished = true
          } else if (that.activeName == 'all') {
            that.formData.offset = 0
            that.searchStatus = false
            that.course('ing')
            console.log('会议数据完了，抓课程数据')
            return false
          }
        }
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        }
        that.searchStatus = false
      })
    },
    //课程
    course(ing = '') {
      let that = this
      if (that.searchStatus || (that.activeName == 'all' && that.level != 2)) {
        return false
      }
      if (that.formData.offset == 0 && ing != 'ing') {
        that.list = []
      }
      console.log('课程数据', that.level)
      that.searchStatus = true
      that.$api.COURSE_GET(that.formData).then(res => {
        if (res.length === 0 && that.activeName != 'all') {
          that.word = '暂无数据'
          return false
        }
        res.forEach((item, index) => {
          that.list.push({
            id: item.id,
            title: item.title,
            type_title: that.activeName == 'all' && index == 0 ? '课程' : '',
            type: 'course',
            remark: item.author + '  ' + item.author_title
          })
        })
        if (res.length < that.formData.limit) {
          that.level = 3
          if (that.activeName != 'all') {
            that.finished = true
          } else if (that.activeName == 'all') {
            that.formData.offset = 0
            that.searchStatus = false
            that.article('ing')
            console.log('课程数据完了，抓智库数据')
            return false
          }
        }
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        }
        that.searchStatus = false
      })
    },
    //智库
    article(ing = '') {
      let that = this
      if (that.searchStatus || (that.activeName == 'all' && that.level != 3)) {
        return false
      }
      console.log('智库数据', that.level)
      if (that.formData.offset == 0 && ing != 'ing') {
        that.list = []
      }
      that.searchStatus = true
      that.$api.INDEX_ARTICLE_GET(that.formData).then(res => {
        if (res.length === 0 && that.activeName != 'all') {
          that.word = '暂无数据'
          return false
        }
        res.forEach((item, index) => {
          that.list.push({
            id: item.id,
            title: item.title,
            type_title: that.activeName == 'all' && index == 0 ? '智库' : '',
            type: 'article',
            remark: ''
          })
        })
        if (res.length < that.formData.limit) {
          that.level = 4
          if (that.activeName != 'all') {
            that.finished = true
          } else if (that.activeName == 'all') {
            that.formData.offset = 0
            that.searchStatus = false
            that.activity('ing')
            console.log('智库数据完了，抓学会动态数据')
            return false
          }
        }
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        }
        that.searchStatus = false
      })
    },
    //学会活动
    activity(ing = '') {
      let that = this
      if (that.searchStatus || (that.activeName == 'all' && that.level != 4)) {
        return false
      }
      if (that.formData.offset == 0 && ing != 'ing') {
        that.list = []
      }
      console.log('学会活动数据', that.level)
      that.searchStatus = true
      that.$api.LEARN_ACTIVITY_GET(that.formData).then(res => {
        if (res.length === 0 && that.activeName != 'all') {
          that.word = '暂无数据'
          return false
        }
        res.forEach((item, index) => {
          that.list.push({
            id: item.id,
            title: item.title,
            type_title: index == 0 ? '学会活动' : '',
            type: 'activity',
            remark: ''
          })
        })
        if (res.length < that.formData.limit) {
          that.level = 5
          if (that.activeName == 'all' || that.activeName == 'organ') {
            that.formData.offset = 0
            that.searchStatus = false
            that.dynamic('ing')
            console.log('学会活动数据完了，抓学会动态数据')
            return false
          }
        }
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        }
        that.searchStatus = false
      })
    },
    //学会动态
    dynamic(ing = '') {
      let that = this
      if (that.searchStatus || (that.activeName == 'all' && that.level != 5)) {
        return false
      }
      console.log('学会动态数据', that.level)
      if (that.formData.offset == 0 && ing != 'ing') {
        that.list = []
      }
      that.searchStatus = true
      that.$api.LEARN_DYNAMIC_GET(that.formData).then(res => {
        res.forEach((item, index) => {
          that.list.push({
            id: item.id,
            title: item.title,
            type_title: index == 0 ? '学会动态' : '',
            type: 'dynamic',
            remark: ''
          })
        })
        if (res.length < that.formData.limit) {
          that.level = 6
          if (that.activeName == 'all'  || that.activeName == 'organ') {
            that.formData.offset = 0
            that.searchStatus = false
            that.science('ing')
            console.log('学会动态数据完了，抓科研项目数据')
            return false
          }
        }
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        }
        that.searchStatus = false
      })
    },
    //科研项目
    science(ing = '') {
      let that = this
      if (that.searchStatus || (that.activeName == 'all' && that.level != 6)) {
        return false
      }
      console.log('科研项目数据', that.level)
      if (that.formData.offset == 0 && ing != 'ing') {
        that.list = []
      }
      that.searchStatus = true
      that.$api.SCIENCE_GET(that.formData).then(res => {
        res.forEach((item, index) => {
          that.list.push({
            id: item.id,
            title: item.title,
            type_title: index == 0 ? '科研项目' : '',
            type: 'science',
            remark: ''
          })
        })
        if (res.length < that.formData.limit) {
          that.finished = true
        }
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        }
        that.searchStatus = false
      })
    },
    toBVueInfo(type, toid) {
      console.log(type, toid)
      if (type == 'live') {
        this.toUrl = '/liveDetail'
      } else if (type == 'meeting') {
        this.toUrl = '/meetDetail'
      } else if (type == 'course') {
        this.toUrl = '/courseDetail'
      } else if (type == 'article') {
        this.toUrl = '/medicineDetail'
      } else if (type == 'dynamic' || type == 'science' || type == 'activity') {
        this.toUrl = '/organDetail'
        this.$router.push({path: this.toUrl, query: {id: toid, action: type}})
        return false
      }
      this.$router.push({path: this.toUrl, query: {id: toid}})
    }
  }
}
</script>
<style lang="less" scoped>

.search-tab{
  ::v-deep .van-tab__text{
    font-size:18px;
    color:#000;
    font-weight:bold;
  }

  ::v-deep .van-tabs__line{
    width: 5vw;
    height: 1vw;
    background-color:#2580FE;
  }
}

.search-type{
  display:block;
  padding:10px 20px 0 20px;
  font-size:16px;
}
.search-item{
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin:0 20px;
  padding:13px 0;
  border-bottom:1px solid #e0e0e0 ;
  

  h4{
    font-size:15px;
    line-height:20px;
    margin:0;
  }
  p{
    font-size:12px;
    padding-top:10px;
  }
}
</style>